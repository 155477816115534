(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-draw-additions/assets/javascripts/draws.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-draw-additions/assets/javascripts/draws.js');
"use strict";

const {
  useCallback
} = React;
const {
  Weeks: BaseWeek
} = svs.components.tipsen.couponAdditionsComponents;
const Draws = () => {
  const getWeeksText = useCallback(weeks => {
    let text = '';
    weeks.forEach(week => {
      if (week === weeks[0]) {
        text += week;
      } else if (week === weeks[weeks.length - 1]) {
        text += " och ".concat(week);
      } else {
        text += ", ".concat(week);
      }
    });
    return text;
  }, []);
  const presentableWeek = useCallback(numberOfWeeks => "".concat(numberOfWeeks, " omg\xE5ng").concat(numberOfWeeks > 1 ? 'ar' : ''), []);
  const getDialog = useCallback(weeks => "Spela samma rad flera omg\xE5ngar i f\xF6ljd. V\xE4lj mellan ".concat(getWeeksText(weeks), " omg\xE5ngar."), [getWeeksText]);
  return React.createElement(BaseWeek, {
    description: "V\xE4lj hur m\xE5nga omg\xE5ngar du vill spela din rad.",
    getDialog: getDialog,
    icon: "calendar-week",
    legend: "Antal omg\xE5ngar spelet ska p\xE5g\xE5",
    presentableWeek: presentableWeek,
    title: "Spela fler omg\xE5ngar"
  });
};
setGlobal('svs.components.tipsen.couponDrawAddition.Draws', Draws);

 })(window);